import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;
    const authToken = localStorage.getItem('authToken');
    const csrfToken = localStorage.getItem('csrfToken');

    // If authToken exists, set the Authorization header
    if (authToken) {
      headers = headers.set('Authorization', `Bearer ${authToken}`);
    }

    // If csrfToken exists, set the X-CSRF-TOKEN header
    if (csrfToken) {
      headers = headers.set('X-CSRF-TOKEN', csrfToken);
    }

    // Clone the request with the updated headers
    const authReq = req.clone({ headers });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(authReq);
  }
}
