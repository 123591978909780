import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap } from 'rxjs';

@Component({
  selector: 'lib-logout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss',
})
export class LogoutComponent implements OnInit {

  constructor(private auth: AuthService, private http: HttpClient, private router: Router) {
  }

  ngOnInit() {
    this.auth.logout().pipe(
      tap(() => {
        this.router.navigate(['/']);
      })
    ).subscribe();
  }
}
