import { Route } from '@angular/router';
import { CharacterProfileComponent, HomepageComponent, PageNotFoundComponent } from '@nx-ui/shared';
import { AuthGuard, LoginComponent, LogoutComponent } from '@nx-ui/auth';
import { LeaderboardComponent } from '@nx-ui/shared';


export const appRoutes: Route[] = [
  { path: '', component: HomepageComponent },  // Root component
  { path: 'character', component: CharacterProfileComponent, canActivate: [AuthGuard] },  // Root component
  { path: 'leaderboard', component: LeaderboardComponent, canActivate: [AuthGuard] },  // Root component
  { path: 'login', component: LoginComponent },  // Logout component
  { path: 'logout', component: LogoutComponent },  // Logout component
  { path: '**', component: PageNotFoundComponent }  // 404 Page not found
];
