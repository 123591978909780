import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, Observable, switchMap, tap } from 'rxjs';
import { User } from '../types/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // TODO: Update to use global variables/configuration
  private baseUri = 'https://anathon.com:8443/api/auth';

  constructor(private http: HttpClient) {
  }

  getCsrfToken(): Observable<{ csrf_token: string }> {
    return this.http.get<{
      csrf_token: string
    }>(`${this.baseUri}/csrf-token`, { withCredentials: true }).pipe(
      tap(r => {
        console.log(r);
        localStorage.setItem('csrfToken', r.csrf_token);
      })
    );
  }

  login(user: User): Observable<{ access_token: string; token: string }> {
    // TODO update logic to use it from interceptor/globally

    return this.getCsrfToken().pipe(
      switchMap(() => {
        return this.http.post<{
          access_token: string;
          token: string
        }>(`${this.baseUri}/login`, user, { withCredentials: true }).pipe(
          tap(response => {
            console.log(response);
            localStorage.setItem('authToken', response.token);
          }));
      }));
  }

  logout() {
    return this.http.post<null>(`${this.baseUri}/logout`, null, { withCredentials: true }).pipe(
      finalize(() => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('csrfToken');
      })
    );
  }

  isLoggedIn(): boolean {
    // Check if there's a token in localStorage
    return !!localStorage.getItem('authToken');
  }
}
