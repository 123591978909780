import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { delay, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CharacterService, ItemEffectService, ItemService, ThemeService } from '@nx-ui/wge-api';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private themeService: ThemeService,
    private characterService: CharacterService,
    private itemService: ItemService,
    private itemEffectService: ItemEffectService,
    private router: Router
  ) {}

  ngOnInit() {
    console.warn('auto-login: Laimonas');
    this.authService.login({ email: 'laimonas171@gmail.com', password: 'laimonas' }).pipe(
      delay(3000),
      tap(()=> {
        this.router.navigate([`character`]);
      })
    ).subscribe();
  }
}
