<ng-container *ngIf="isLoaded">

  <layout [isLoggedIn]="isLoggedIn">
    <router-outlet></router-outlet>
  </layout>

</ng-container>

<ng-container *ngIf="!isLoaded">
  <p class="loader"></p>
</ng-container>
