import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AuthService } from '@nx-ui/auth';
import { LayoutComponent } from '../layout/layout.component';

@Component({
  selector: 'homepage',
  standalone: true,
  imports: [CommonModule, RouterLink, LayoutComponent],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss',
})
export class HomepageComponent implements OnInit {
  isUserLoggedIn = false;
  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn();
  }
}
