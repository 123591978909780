import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ItemEffect } from '../types/item-effect.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ItemEffectService {
  private apiUrl = 'http://localhost/item-effects';

  constructor(private http: HttpClient) {}

  // Get all item effects
  getAllItemEffects(): Observable<ItemEffect[]> {
    return this.http.get<ItemEffect[]>(this.apiUrl);
  }

  // Get a single item effect by ID
  getItemEffect(id: number): Observable<ItemEffect> {
    return this.http.get<ItemEffect>(`${this.apiUrl}/${id}`);
  }

  // Create a new item effect
  createItemEffect(itemEffect: ItemEffect): Observable<ItemEffect> {
    return this.http.post<ItemEffect>(this.apiUrl, itemEffect);
  }

  // Update an existing item effect
  updateItemEffect(id: number, itemEffect: ItemEffect): Observable<ItemEffect> {
    return this.http.put<ItemEffect>(`${this.apiUrl}/${id}`, itemEffect);
  }

  // Delete an item effect
  deleteItemEffect(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
