import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Character, CharacterService, ThemeService } from '@nx-ui/wge-api';
import { tap } from 'rxjs';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'character-profile',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './character-profile.component.html',
  styleUrl: './character-profile.component.scss',
})
export class CharacterProfileComponent implements OnInit {
  character?: Character;

  constructor(private characterService: CharacterService, private themeService: ThemeService) {
  }

  ngOnInit() {
    this.themeService.getInactiveThemes().subscribe();

      this.characterService.getMyCharacter().pipe(
        tap(r=> {
          this.character = r;
        })
      ).subscribe();
  }
}
