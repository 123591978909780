<div class="layout-container">
  <!-- Header (small to accommodate notch/dynamic island) -->
  <header class="header">
    <h1>My Game</h1>
  </header>

  <!-- Content area (main body) -->
  <div class="content">
    <div class="content" *ngIf="isLoggedIn">

      <div>
        <a routerLink="logout" *ngIf="isLoggedIn">Logout</a>
      </div>
    </div>
    <div *ngIf="!isLoggedIn">
      <a routerLink="login">Login</a>
    </div>
    <ng-content></ng-content>
  </div>

  <!-- Bottom navigation with 4-5 buttons -->
  <nav class="bottom-nav" *ngIf="isLoggedIn">
    <a class="nav-link" routerLink="/character">Home</a>
    <a class="nav-link" routerLink="/quests">Quests</a>
    <a class="nav-link" routerLink="/leaderboard">Leaderboard</a>
    <a class="nav-link" routerLink="/settings">Settings</a>
  </nav>
</div>
