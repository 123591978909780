import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Theme } from '../types/theme';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  baseUri = 'https://anathon.com:8443/api/themes';
  constructor(private http: HttpClient) {}

  getAllThemes(): Observable<Theme> {
    return this.http.get<Theme>(`${this.baseUri}`);
  }

  getActiveThemes(): Observable<Theme> {
    return this.http.get<Theme>(`${this.baseUri}/active`);
  }

  getInactiveThemes(): Observable<Theme> {
    return this.http.get<Theme>(`${this.baseUri}/inactive`);
  }
}
