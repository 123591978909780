import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Character } from '../types/character.model';

@Injectable({
  providedIn: 'root',
})
export class CharacterService {
  baseUri = 'https://anathon.com:8443/api/characters';
  constructor(private http: HttpClient) {}

  // Get all characters
  getAllCharacters(): Observable<Character[]> {
    return this.http.get<Character[]>(this.baseUri);
  }

  // Get a single character by ID
  getCharacter(id: number): Observable<Character> {
    return this.http.get<Character>(`${this.baseUri}/${id}`);
  }

  getMyCharacter(): Observable<Character> {
    return this.http.get<Character>(`${this.baseUri}/me`);
  }

  // Create a new character
  createCharacter(character: Character): Observable<Character> {
    return this.http.post<Character>(this.baseUri, character);
  }

  // Update an existing character
  updateCharacter(id: number, character: Character): Observable<Character> {
    return this.http.put<Character>(`${this.baseUri}/${id}`, character);
  }

  // Delete a character
  deleteCharacter(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUri}/${id}`);
  }
}
