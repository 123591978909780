import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '@nx-ui/auth';
import { CharacterService, ItemEffectService, ItemService, ThemeService } from '@nx-ui/wge-api';
import { finalize, switchMap } from 'rxjs';
import { LayoutComponent } from '@nx-ui/shared';

@Component({
  standalone: true,
  imports: [RouterModule, CommonModule, LayoutComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'game-ui';

  isLoaded = false;

  get isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  constructor(
    private authService: AuthService,
    private themeService: ThemeService,
    private characterService: CharacterService,
    private itemService: ItemService,
    private itemEffectService: ItemEffectService
  ) {
  }

  ngOnInit() {
    this.authService.getCsrfToken().pipe(
      switchMap(() => this.themeService.getAllThemes().pipe(
        finalize(() => {
          this.isLoaded = true;
        }))))
      .subscribe();
  }
}
