import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { tap } from 'rxjs';
import { Character, CharacterService } from '@nx-ui/wge-api';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lib-leaderboard',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './leaderboard.component.html',
  styleUrl: './leaderboard.component.scss',
})
export class LeaderboardComponent implements OnInit {

  characters: Character[] = []
  constructor(private characterService: CharacterService) {
  }
  ngOnInit() {
    this.characterService.getAllCharacters().pipe(
      tap(r=> {
        this.characters = r;
      })
    ).subscribe();
  }
}
